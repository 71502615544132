import {defineComponent, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {AssetApi, SystemApi, UserApi} from "../../../../target/api";
import {
  Asset,
  SubtitleOptions,
} from "../../../../target/api/de/moovit/titletoolserver/model";

export default defineComponent({
  components: {},

  setup: function (props) {
    const route = useRoute();
    const store = useStore();

    let assetApi: AssetApi = new AssetApi();

    let currentPage = ref(1);
    let currentExpirationDate = ref("");
    let transitionDir = ref("slide-right");
    let assetName = ref(undefined);
    let approver = ref<string>("");
    let isApprover = ref<boolean>(false);
    let groups = ref(undefined);
    let selectedAudioTrack = ref(undefined);

    let translator = ref(undefined);
    let expirationDate = ref(undefined);
    let subtitleOption = ref<SubtitleOptions>();
    let languages = ref<undefined |
      {
        name: string;
        value: string;
        autoTranslate: boolean;
        autoTranslateAvailable: boolean;
      }[]
    >(undefined);
    let headerItem = ref<Asset>();

    let getCircleClass = (page: number, currentPage: number) => {
      if (page < currentPage) return " pi-check-circle";
      if (page > currentPage) return " pi-circle-on col-menu-color";
      if (page == currentPage) return " pi-circle-on ";
    };

    onBeforeMount(() => {
      assetApi
        .getAssetById("" + route.query.id)
        .then((getAssetByIdResponse) => {
          headerItem.value = getAssetByIdResponse.data;
          console.debug("AddJob - Item", headerItem.value);

          let tAssets = {
            t: "activityBreadcrumbs.assets",
            e: undefined,
          };
          let tAddJobTo = {
            t: "activityBreadcrumbs.addJobTo",
            e: [headerItem.value.name],
          };
          store.commit("SET_ACTIVITY_BREADCRUMBS", [tAssets, tAddJobTo]);
        });
    });

    return {
      headerItem,
      currentPage,
      assetName,
      currentExpirationDate,
      approver,
      isApprover,
      groups,
      selectedAudioTrack,
      translator,
      expirationDate,
      subtitleOption,
      languages,
      getCircleClass,
      transitionDir,
    };
  },
});
