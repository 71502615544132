import {UserApi} from '../../../target/api';
import {SearchUsersObject, User} from '../../../target/api/de/moovit/titletoolserver/model';
import {defineComponent, onMounted, ref, watch} from 'vue';

export default defineComponent({
  props: ['modelValue', 'label', 'userRoles', 'customId', 'invalid', 'customBlurFunction', 'dropdownHeight'],
  components: {},
  setup: function (props, context) {
    let userApi = new UserApi();
    let innerModelValue = ref<User>();
    let suggestions: any = ref(undefined);
    let users = ref<User[]>([]);
    let page: number = 0;
    let pageSize: number = 20;
    let searchText: any = '';
    let addingUsers = ref(false);
    let added0Users = ref(false);

    let getUsers: any = async () => {
      let searchUsersObject: SearchUsersObject = {};
      searchUsersObject.page = page;
      searchUsersObject.pageSize = pageSize;
      searchUsersObject.searchText = searchText;
      searchUsersObject.locked = false;
      searchUsersObject.role = props.userRoles;
      users.value = (await userApi.searchUsers(searchUsersObject)).data;
    };

    watch(props, () => {
      innerModelValue.value = props.modelValue;
    });

    watch(innerModelValue, () => {
      context.emit('update:modelValue', innerModelValue.value);
      if (props.customBlurFunction) {
        props.customBlurFunction();
      }
    });

    onMounted(() => {
      if (props.modelValue) {
        innerModelValue.value = props.modelValue;
      }

      getUsers();
    });

    return {
      innerModelValue,
      users,
    };
  },
});
