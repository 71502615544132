<template>
  <div class="flex w-full pl-2.5 flex-col" style="height: calc(70vh - 130px)">
    
    <div class="flex flex-center flex-col h-1/6 m-1 border-radius-8 bor-dark-blue-30 cursor-pointer"
         @click="onClickFieldArea(currentFieldCounter - 2)">
      <div class="w-full">
        {{ getFieldAtPosition(currentFieldCounter - 2) ? getFieldAtPosition(currentFieldCounter - 2).srcValue : "" }}
      </div>
      <div class="border-t-2 w-full">
        {{ getFieldAtPosition(currentFieldCounter - 2) ? getFieldAtPosition(currentFieldCounter - 2).value : "" }}
      </div>
    </div>
    
    <div class="flex flex-center flex-col h-1/6 m-1 border-radius-8 bor-dark-blue-30 cursor-pointer"
         @click="onClickFieldArea(currentFieldCounter - 1)">
      <div class="w-full">
        {{ getFieldAtPosition(currentFieldCounter - 1) ? getFieldAtPosition(currentFieldCounter - 1).srcValue : "" }}
      </div>
      <div class="border-t-2 w-full">
        {{ getFieldAtPosition(currentFieldCounter - 1) ? getFieldAtPosition(currentFieldCounter - 1).value : "" }}
      </div>
    </div>
    
    <div class="flex flex-center flex-col m-1 h-2/6 border-radius-8 bor-dark-blue">
      <div class="relative border-b-2 w-full min-h-10">
        <div class="absolute bottom-0">
          {{ getFieldAtPosition(currentFieldCounter) ? getFieldAtPosition(currentFieldCounter).srcValue : "" }}
        </div>
      </div>
      <Textarea
        id="currentTranslation"
        v-model="currentTranslation"
        :value="currentTranslation"
        class="bg-tt-white flex-grow w-full p-3 h-5 m-1 bor-transparent"
        
        :disabled="translationDisabled"
        @blur="saveCurrentFieldValuesEmit()"
        
        style="height: 40px; font-size: calc(8px + 6 * ((80vw - 390px) / 680))"
        :style="isTextRTL ? 'direction: rtl' : ''"
      />
    </div>
    
    <div class="flex flex-center flex-col h-1/6 m-1 border-radius-8 bor-dark-blue-30 cursor-pointer"
         @click="onClickFieldArea(currentFieldCounter + 1)">
      <div class="w-full">
        {{ getFieldAtPosition(currentFieldCounter + 1) ? getFieldAtPosition(currentFieldCounter + 1).srcValue : "" }}
      </div>
      <div class="border-t-2 w-full">
        {{ getFieldAtPosition(currentFieldCounter + 1) ? getFieldAtPosition(currentFieldCounter + 1).value : "" }}
      </div>
    </div>
    
    <div class="flex flex-center flex-col h-1/6 m-1 border-radius-8 bor-dark-blue-30 cursor-pointer"
         @click="onClickFieldArea(currentFieldCounter + 2)">
      <div class="w-full">
        {{ getFieldAtPosition(currentFieldCounter + 2) ? getFieldAtPosition(currentFieldCounter + 2).srcValue : "" }}
      </div>
      <div class="border-t-2 w-full">
        {{ getFieldAtPosition(currentFieldCounter + 2) ? getFieldAtPosition(currentFieldCounter + 2).value : "" }}
      </div>
    </div>
  </div>
</template>

<script src="./AdvancedTranslationArea.ts"></script>
