import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';

export default defineComponent({
  components: {},
  props: ['assetItemId', 'fields', 'currentFieldCounter', 'translationDisabled', 'isTextRTL'],
  setup: function (props, context) {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    let currentMaster = ref<string>("");
    let currentTranslation = ref<string>("");

    let currentTranslationFieldIndex = ref<number>(-1);

    let getFieldAtPosition = (index: number) => {
      return props.fields[index] ? props.fields[index] : undefined
    }

    let saveCurrentFieldValuesEmit = () => {
      emitter.emit('updateFieldValues' + props.assetItemId, currentTranslation.value);
    }

    let onClickFieldArea = (index: number) => {
      if (props.fields[index] != undefined) {
        emitter.emit('goToField' + props.assetItemId, index);
      }
    }

    watch(props, () => {
      if (props.currentFieldCounter != currentTranslationFieldIndex.value) {
        currentTranslationFieldIndex.value = props.currentFieldCounter;

        currentTranslation.value = props.fields[props.currentFieldCounter].value;
        currentMaster.value = props.fields[props.currentFieldCounter].srcValue;
      }
    });

    onMounted(() => {
    });

    return {
      onClickFieldArea,
      saveCurrentFieldValuesEmit,
      getFieldAtPosition,
      currentMaster,
      currentTranslation,
    };
  },
});
