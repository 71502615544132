<template>
  <pDialog :header="user ? $t('general.editUser') : $t('general.addUser')"
           v-model:visible="isActive"
           :draggable="false"
           :maximizable="false"
           :modal="true"
           style="width: 70%; margin-left: 50%; margin-right: 50%">
    <div style="width: 600px; height: 450px">
      <div style="display: flex; align-items: center; justify-content: center; flex-direction: column">
        <div class="settings-header-line">
          <div class="settings-header-text">
            {{$t('addUser.personalData')}}
          </div>
        </div>
        <div style="display: flex; flex-direction: column">
          
          <!-- These two elements catch the autofill from the browser so we dont have to deal with this -->
          <InputText type="email" style="opacity: 0; height: 0; width: 0; position: absolute"></InputText>
          <InputText type="password" style="opacity: 0; height: 0; width: 0; position: absolute"></InputText>
          
          <div style="display: flex; align-items: center"
               :style="isWindowTooSmall ? 'flex-direction: column; gap: 0;'
               : 'flex-direction: row; gap: 10px'">
            
            <!--          Username -->
            <div style="height: 50px; position: relative; margin: 5px">
              <Transition name="fade">
                <div v-if="existingName && !user" style="color: red; position: absolute; z-index: 10000">
                  {{errorMessage}}
                </div>
              </Transition>
              <CustomInputText
                :label="$t('general.username') + '*'"
                v-model:modelValue="currentUsername"
                style="width: 250px; height: 50px"
                class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
                :class="!submitted || currentUsername || existingName ? 'bor-menu-color' : 'bor-reject'"
                :disabled="user"
                :autocomplete="false"
                @input="verifyUsername"
              />
            </div>
            <!--          Email -->
            <CustomInputText
              :label="$t('general.eMail') + '*'"
              v-model:modelValue="currentEmail"
              style="width: 250px; height: 50px"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
              :class="!submitted || currentEmail ? 'bor-menu-color' : 'bor-reject'"
            />
          </div>
          
          <div style="display: flex; align-items: center"
               :style="isWindowTooSmall ? 'flex-direction: column; gap: 0;'
               : 'flex-direction: row; gap: 10px'">
            <!--          Password -->
            <CustomInputText
              :label="$t('general.password') + '*'"
              v-model:modelValue="currentPassword"
              type="password"
              autocomplete="off"
              style="width: 250px; height: 50px; margin: 5px"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
              :class="!submitted || currentPassword ? 'bor-menu-color' : 'bor-reject'"
            />
            
            <!--          Verify Password -->
            <CustomInputText
              :label="$t('general.verifyPassword') + '*'"
              v-model:modelValue="currentConfirmPassword"
              type="password"
              :autocomplete="false"
              style="width: 250px; height: 50px"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
              :class="!submitted || currentConfirmPassword ? 'bor-menu-color' : 'bor-reject'"
            />
          </div>
          
          <div style="display: flex; align-items: center"
               :style="isWindowTooSmall ? 'flex-direction: column; gap: 0;'
               : 'flex-direction: row; gap: 10px'">
            <!--          Firstname -->
            <CustomInputText
              :label="$t('general.firstName') + '*'"
              v-model:modelValue="currentFirstname"
              style="width: 250px; height: 50px; margin: 5px"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
              :class="!submitted || currentFirstname ? 'bor-menu-color' : 'bor-reject'"
            />
            
            <!--          Surname -->
            <CustomInputText
              :label="$t('general.surname') + '*'"
              v-model:modelValue="currentSurname"
              style="width: 250px; height: 50px"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
              :class="!submitted || currentSurname ? 'bor-menu-color' : 'bor-reject'"
            />
          </div>
        </div>
      </div>
      
      <div style="display: flex; align-items: center; justify-content: center; flex-direction: column">
        <div class="settings-header-line">
          <div class="settings-header-text">
            {{$t('addUser.organisationData')}}
          </div>
        </div>
        <div style="display: flex;  flex-direction: column">
          
          <div style="display: flex; align-items: center"
               :style="isWindowTooSmall ? 'flex-direction: column; gap: 0;'
               : 'flex-direction: row; gap: 10px'">
            <!--          Role -->
            <UserRoleDropdown
              v-model:modelValue="currentRole"
              :placeholder="$t('general.role') + '*'"
              :show-clear="false"
              :class="!submitted || currentRole ? 'bor-menu-color' : 'bor-reject'"
              style="width: 250px; height: 50px; margin: 5px"
            />
            
            <!--          Group -->
            <MultiSelect v-model="currentGroups"
                         :options="groups"
                         optionLabel="id"
                         :placeholder="$t('general.group') + '*'"
                         :maxSelectedLabels="3"
                         filter
                         style="width: 250px; height: 50px"
                         class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"/>
          </div>
          
          <div style="display: flex; align-items: center"
               :style="isWindowTooSmall ? 'flex-direction: column; gap: 0;'
               : 'flex-direction: row; gap: 10px'">
            <!--          Country -->
            <AutoCompleteLanguage
              v-model:modelValue="currentCountry"
              :placeholder="$t('general.country') + '*'"
              :text-over-flow-width="190"
              style="width: 250px; height: 50px; margin: 5px"
              class="border-radius-8 col-dark-blue bg-transparent"
              :class="!submitted || currentCountry.name ? 'bor-menu-color' : 'bor-reject'"
            />
            
            <!--          Default Language -->
            <AutoCompleteLanguage
              v-model:modelValue="currentDefaultLanguage"
              :placeholder="$t('general.defaultLanguage')"
              :text-over-flow-width="190"
              style="width: 250px; height: 50px"
              class="border-radius-8 col-dark-blue bor-menu-color bg-transparent"
            />
          </div>
          
          <div style="display: flex; align-items: center"
               :style="isWindowTooSmall ? 'flex-direction: column; gap: 0;'
               : 'flex-direction: row; gap: 10px'">
            <!--          Cost Center -->
            <CustomInputText
              :label="$t('general.costCenter')"
              v-model:modelValue="currentCostCenter"
              style="width: 250px; height: 50px; margin: 5px"
              class="border-radius-12 col-dark-blue bor-dark-blue-60 bg-transparent"
            />
            
            <Button class="bg-dark-blue border-radius-12 col-white bor-menu-color"
                    style="width: 250px; height: 50px"
                    :label="user ? $t('general.updateUser') : $t('general.createUser')"
                    @click="onClickDone(); valid ? isActive = false : ''"/>
          </div>
        </div>
      </div>
    </div>
  </pDialog>
</template>

<style scoped>
div {
  background-color: inherit;
}

</style>

<script src="./ManageUserDialog.ts"></script>
