import {defineComponent, onMounted, ref} from 'vue';
import {UserGroupApi} from '../../../target/api';
import {UserGroup} from '../../../target/api/de/moovit/titletoolserver/model';
import {useStore} from 'vuex';
import main from '@/main'

export default defineComponent({
  components: {},
  setup: function () {
    const store = useStore();

    let isActive = ref<boolean>(false);

    let locked = ref(false);
    let valid = ref();
    let currentGroupName = ref();
    let currentCostCenter = ref();
    let submitted = ref();

    let existingGroups = ref<UserGroup[]>();
    let existingName = ref<boolean>(false);

    let errorMessage = ref<string>("Group name already existing.");

    let userGroupApi = new UserGroupApi();

    let toggleDialog = () => {
      isActive.value = !isActive.value;
    }

    let verifyGroupName = (): boolean => {
      for (const existingGroup of existingGroups.value!) {
        if (existingGroup.id == currentGroupName.value) {
          errorMessage.value = "Group name already existing.";
          existingName.value = true;
          return false;
        }
      }
      if (currentGroupName.value.length >= 16) {
        errorMessage.value = "Groupname too long.";
        existingName.value = true;
        return false;
      }

      existingName.value = false;
      return true;
    }

    let commitGroup = async () => {
      submitted.value = true;
      if (!currentGroupName.value) {
        return;
      }

      if (!verifyGroupName()) {
        valid.value = false;
        return;
      }

      valid.value = true;

      const userGroup: UserGroup = {};
      userGroup.name = currentGroupName.value;
      userGroup.id = currentGroupName.value;
      if (currentCostCenter.value) {
        userGroup.costCenter = currentCostCenter.value;
      }
      userGroup.assets = 0;
      userGroup.jobs = 0;

      await userGroupApi.addUserGroup(userGroup);

      currentGroupName.value = "";
      currentCostCenter.value = "";
      submitted.value = false;

      main.config.globalProperties.$emitter.emit('search-groups', null);
      toggleDialog();
    };

    onMounted(async () => {
      existingGroups.value = (await userGroupApi.searchUserGroups(0, 1000)).data;
    });

    return {
      toggleDialog,
      isActive,
      locked,
      valid,
      verifyGroupName,
      existingName,
      currentGroupName,
      currentCostCenter,
      submitted,
      commitGroup,
      errorMessage,
    };
  },
});
