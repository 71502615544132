<template>
  <div style="padding-left: 24px; height: calc(100vh - 140px)">
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex; align-items: center;">
        <RoundedLabel
          @click="
            $store.commit('SHOW_ADD_FILTER_DIALOG', {
              hideLocked: true,
              hideDefaultLanguage: true,
              hideRoleDropdown: true,
            })
          "
          leftIcon="pi pi-filter"
          :text="$t('general.addFilter')"
          size="l"
          class="bor-dark-blue"
          style="cursor:pointer; height:40px; margin-right:24px;"
        />
        <FilterBreadcrumb :items="$store.state.filterBreadcrumbs" maxWidth="calc(100vw - 555px)" />
      </div>

      <SearchField v-model="searchText" style="padding-right:38px;" :blurFunction="onBlurSearchField" />
    </div>
    <!-- {{ jobs[0].masterAssetItem.language }} -->
    <DataTable
      id="jobsDataTable"
      class="p-datatable-custom"
      :value="jobs"
      scrollable
      scrollHeight="flex"
      removableSort
      selectionMode="single"
      @rowClick="setLastClickedItem"
      @sort="onDataTableSort"
      :rows="8"
    >
      <template #loading>
        Loading data....
      </template>

      <Column sortable field="assetStatus" :header="$t('general.status')" style="width: 5%">
        <template #body="slotProps">
          <span
            class="first-col col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow-first-child' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <RoundedLabel
              @click="
                $store.commit('ADD_FILTER_BREADCRUMB', {
                  name: slotProps.data.assetStatus,
                  type: 'STATUS',
                });
                $emitter.emit('search-jobs', null);
              "
              :text="getMappedAssetStatus(slotProps.data.assetStatus)"
              :class="
                slotProps.data.assetStatus
                  ? 'bg-' +
                    slotProps.data.assetStatus
                      .toLowerCase()
                      .split(' ')
                      .join('-') +
                    '-light ' +
                    'bor-' +
                    slotProps.data.assetStatus
                      .toLowerCase()
                      .split(' ')
                      .join('-')
                  : ''
              "
            />
          </span>
        </template>
      </Column>

      <Column sortable field="name" :style="'width: ' + selectedColumns.length / 100 + '%'" :header="$t('general.name')">
        <template #body="slotProps">
          <span
            class="col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <span
              style="width: inherit"
              v-tooltip="slotProps.data.name.length > 22 ? slotProps.data.name : ''"
              class="handle-font-overflow"
            >
              {{ slotProps.data.name }}
            </span>
          </span>
        </template>
      </Column>

      <Column
        v-if="selectedColumns.includes('general.originalLanguage')"
        sortable
        sortField="masterAssetItem.language"
        :header="$t('general.originalLanguage')"
      >
        <template #body="slotProps">
          <span
            class="col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <div
              @click="
                $store.commit('ADD_FILTER_BREADCRUMB', {
                  name: slotProps.data.masterAssetItem.language,
                  type: 'LANGUAGE',
                });
                $emitter.emit('search-jobs', null);
              "
            >
              <Flag
                :countryFlag="slotProps.data.masterAssetItem.language"
                class="hover transitions"
                v-tooltip="slotProps.data.masterAssetItem.language"
                style="margin: 4px; cursor: pointer"
              />
            </div>
          </span>
        </template>
      </Column>

      <Column
        v-if="selectedColumns.includes('general.expiration')"
        sortable
        field="tokenObject.expiration"
        :header="$t('general.expiration')"
      >
        <template #body="slotProps">
          <span
            class="col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow' : ''"
            style="flex-direction: column"
            :id="'job.' + slotProps.data.id"
          >
            <span style="line-height: 20px" :style="isExpired(slotProps.data) ? 'color:red' : ''">
              {{ slotProps.data.tokenObject ? getExpirationText(slotProps.data.tokenObject.expiration) : "" }}
            </span>
            <RoundedLabel
              v-if="isExpired(slotProps.data) && hasPermission('jobs_view/expiration/reset')"
              @click="
                $store.commit('SHOW_CONFIRM_DIALOG', {
                  dialogTitle: 'dialog.areYouSure',
                  dialogContent: {
                    t: 'dialog.resetJob',
                    e: [slotProps.data.name],
                  },
                  confirmCallback: () => {
                    resetJob(slotProps.data);
                  },
                })
              "
              class="bor-menu-color"
              style="cursor: pointer; height: 16px; margin-top: 6px; padding-bottom:2px"
              :text="$t('general.reset')"
            />
          </span>
        </template>
      </Column>

      <Column v-if="selectedColumns.includes('general.id')" sortable field="thirdPartyID" :header="$t('general.id')">
        <template #body="slotProps">
          <span
            class="col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <RoundedLabel
              v-if="slotProps.data.thirdPartyID"
              @click="
                slotProps.data.thirdPartyID
                  ? $store.commit('ADD_FILTER_BREADCRUMB', {
                      name: slotProps.data.thirdPartyID,
                      type: 'ID',
                    })
                  : '';
                slotProps.data.thirdPartyID ? $emitter.emit('search-jobs', null) : '';
              "
              class="bor-menu-color"
              v-tooltip="
                slotProps.data.thirdPartyID
                  ? slotProps.data.thirdPartyID.length > 10
                    ? slotProps.data.thirdPartyID
                    : $t('general.id')
                  : ''
              "
              :text="
                slotProps.data.thirdPartyID
                  ? slotProps.data.thirdPartyID.length > 10
                    ? slotProps.data.thirdPartyID.substring(0, 10) + '...'
                    : slotProps.data.thirdPartyID
                  : ''
              "
            />
          </span>
        </template>
      </Column>

      <Column v-if="selectedColumns.includes('general.languages')" :header="$t('general.languages')">
        <template #body="slotProps">
          <span
            class="col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <div
              v-for="lang in slotProps.data.assetItems"
              @click="
                $store.commit('ADD_FILTER_BREADCRUMB', {
                  name: lang.language,
                  type: 'LANGUAGE',
                });
                $emitter.emit('search-jobs', null);
              "
            >
              <Flag :countryFlag="lang.language" class="hover transitions" v-tooltip="lang.language" style="margin: 4px; cursor: pointer" />
            </div>
          </span>
        </template>
      </Column>

      <Column v-if="selectedColumns.includes('general.groups')" :header="$t('general.groups')">
        <template #body="slotProps">
          <span
            class="col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <RoundedLabel
              @click="onClickGroupFilterBreadcrumb(slotProps.data)"
              :text="slotProps.data.groupID ? slotProps.data.groupID : slotProps.data.groupIds.join(', ')"
              class="bor-menu-color"
            />
          </span>
        </template>
      </Column>

      <Column v-if="selectedColumns.includes('general.translator')" sortable field="translatorID" :header="$t('general.translator')">
        <template #body="slotProps">
          <span
            class="col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <RoundedLabel
              @click="
                $store.commit('ADD_FILTER_BREADCRUMB', {
                  name: slotProps.data.translatorID,
                  type: 'TRANSLATOR',
                });
                $emitter.emit('search-jobs', null);
              "
              :text="slotProps.data.translatorID"
              class="bor-menu-color"
            />
          </span>
        </template>
      </Column>

      <Column>
        <template #header="slotProps" class="flex justify-center card">
          <!-- <label for="ms_select_columns" style="font-weight: normal;">Select Columns</label> -->
          <MultiSelect
            v-model:model-value="selectedColumns"
            style="width: 10px; "
            :options="allColumns"
            placeholder="Select Countries"
            inputId="ms_select_columns"
          >
            <template #header="slotProps">
              <h4 style="padding:5px 0 0 20px">
                Select Columns
              </h4>
            </template>
            <template #option="s">
              {{ $t(s.option) }}
            </template>
          </MultiSelect>
        </template>
        <template #body="slotProps">
          <span
            class="last-col col transitions flex-center"
            :class="jobIdNewlyCreated.includes(slotProps.data.id) ? 'job-shadow-last-child' : ''"
            :id="'job.' + slotProps.data.id"
          >
            <RoundedLabel
              @click="toggleSubscription(slotProps.data)"
              :leftIcon="'pi pi-star' + (isSubscript(slotProps.data) ? '-fill' : '')"
              v-tooltip="$t('tooltip.subscribe')"
              class="bor-menu-color"
              style="margin-right: 10px; cursor: pointer; color: gold"
            />

            <RoundedLabel
              @click="
                $router.push({
                  name: 'editJob',
                  query: { id: slotProps.data.id },
                })
              "
              v-if="$route.path === '/jobs' && hasPermission('jobs_view/edit/items')"
              leftIcon="pi pi-cog"
              class="bor-menu-color"
              v-tooltip="$t('tooltip.editJob')"
              style="margin-right: 10px; cursor: pointer"
            />

            <RoundedLabel
              @click="
                $store.commit('SHOW_CONFIRM_DIALOG', {
                  dialogTitle: 'dialog.areYouSure',
                  dialogContent: {
                    t: 'dialog.deleteJob',
                    e: [slotProps.data.name],
                  },
                  confirmCallback: () => {
                    deleteJob(slotProps.data);
                    $store.commit('SHOW_SNACKBAR', {
                      snackbarContent: { t: 'snackbar.jobDeleted' },
                      snackbarBtnText: $t('snackbar.undo'),
                      snackbarBtnCallback: () => {
                        undoDeleteJob();
                      },
                    });
                  },
                })
              "
              v-if="hasPermission('jobs_view/delete')"
              leftIcon="pi pi-trash"
              class="bor-menu-color"
              style="margin-right: 10px; cursor: pointer"
            />

            <RoundedLabel
              @click="$router.push('jobs')"
              v-if="$route.path === '/editJob' && hasPermission('jobs_view/edit/items')"
              leftIcon="pi pi-times"
              class="bor-menu-color"
              style="margin-right: 10px; cursor: pointer"
            />

            <!--            <DownloadJobDropdown v-if="readyForDownload()" :item="item" />-->
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style lang="scss">
.p-selectable-row:focus {
  outline: none !important;
}

.p-selectable-row td > span > * {
  color: var(--dark-blue);
}

@keyframes new-job {
  0% {
    color: var(--background);
  }
  50% {
    color: var(--badge-succ);
  }
  100% {
    color: var(--background);
  }
}

.job-shadow {
  animation: new-job infinite 1.5s ease-in-out !important;
  box-shadow: 0 2px, 0 -2px !important;
}

.job-shadow-first-child {
  animation: new-job infinite 1.5s ease-in-out !important;
  box-shadow: 0 2px, 0 -2px, -2px 0 !important;
}

.job-shadow-last-child {
  animation: new-job infinite 1.5s ease-in-out !important;
  box-shadow: 0 2px, 0 -2px, 2px 0 !important;
}

.p-selectable-row td > span {
  box-shadow: 0 2px #0d1e4f3d;
  color: var(--menu-color);
}

.p-selectable-row:hover td > span {
  background-color: var(--dark-blue-05);
  box-shadow: none;
  transform: scale(1.02) !important;
  transform-origin: 0 0;
}

.p-datatable-custom .p-datatable-tbody > tr > td {
  border-style: hidden !important;
  background-color: var(--background);
  padding: 10px 0 !important;
}

.p-datatable-custom .p-datatable-thead {
  color: var(--menu-color) !important;
  background-color: transparent !important;
  border: none !important;
}

.p-datatable-custom .p-sortable-column:focus {
  box-shadow: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background) !important;
  border: none !important;
  font-size: 16px;
}

.p-column-header-content,
.p-sortable-column-icon {
  color: var(--dark-blue);
}

.p-sortable-column-icon {
  font-size: 15px;
}

.first-col {
  border-radius: 18px 0 0 18px;
  margin-left: 10px;
}

.last-col {
  border-radius: 0 18px 18px 0;
  margin-right: 10px;
}

.col {
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 10px;
  height: 70px;
  width: 100%;
}

.transitions {
  transition: all 0.1s linear !important; /* vendorless fallback */
  -o-transition: all 0.1s linear !important; /* opera */
  -ms-transition: all 0.1s linear !important; /* IE 10 */
  -moz-transition: all 0.1s linear !important; /* Firefox */
  -webkit-transition: all 0.1s linear !important; /*safari and chrome */
}

.hover:hover {
  transform: scale(1.03);
}

@keyframes load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.p-datatable-tbody > tr {
  animation: 0.2s ease-in-out 0s 1 load;
}

.p-multiselect-panel {
  min-width: 200px !important;
}
</style>

<script src="./JobsView.ts"></script>
