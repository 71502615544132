<template>
  <div style="display: flex; justify-content: space-between; margin-left: 24px">
    <div>
      <div v-if="activeTab === 0" style="display: flex; align-items: center">
        <RoundedLabel
          @click="
            $store.commit('SHOW_ADD_FILTER_DIALOG', {
              hideStatus: true,
              hideTranslator: true,
              hideLanguage: true,
              hideCustomDropdown: true,
            })
          "
          leftIcon="pi pi-filter"
          :text="$t('general.addFilter')"
          size="l"
          class="bor-dark-blue"
          style="cursor: pointer; height: 40px; margin-right: 24px"
        />
        <FilterBreadcrumb :items="$store.state.filterBreadcrumbs" maxWidth="calc(100vw - 750px)"/>
      </div>
      <div v-else style="height: 60px; width: 10px"></div>
    </div>
    <div style="display: flex; align-items: center">
      <div
        v-if="
          activeTab === 0
            ? hasPermission('manage_users/edit/users')
            : hasPermission('manage_users/edit/groups')
        "
        v-tooltip="activeTab === 0 && !groups.length ? $t('general.createGroupForUsers') : ''"
        style="display: flex; align-content: center; align-items: center; padding-right: 20px"
      >
        <div
          v-if="!showAddUserOrGroup && activeTab === 0"
          style="width: 120px; text-align: right; margin-right: 12px"
        >
          {{$t('general.addUser')}}
        </div>
        <div
          v-if="!showAddUserOrGroup && activeTab === 1"
          style="width: 120px; text-align: right; margin-right: 12px"
        >
          {{$t('general.addGroup')}}
        </div>
        
        <Button v-if="activeTab === 0" class="bor-dark-blue border-radius-8 bg-transparent"
                style="width: 42px; height: 42px"
                @click="addUserDialog = !addUserDialog">
          <i
            class="pi pi-plus col-dark-blue"
            style="font-size:16px; font-weight:600"
          />
        </Button>
        <ManageUserDialog v-if="activeTab === 0" v-model:is-visible="addUserDialog"/>
        <AddGroupDialog v-if="activeTab === 1"/>
      </div>
      
      <SearchField
        v-model="searchText"
        style="padding-right: 38px"
        :blurFunction="onBlurSearchField"
      />
    </div>
  </div>
  
  <div style="overflow: hidden; padding: 40px 24px 0 24px; height: calc(100vh - 163px)">
    <TabView v-model:activeIndex="activeTab">
      <TabPanel
        :header="$t('general.users')"
        :disabled="showAddUserOrGroup || showEditUser || showEditGroup"
      >
        <div style="height: calc(100vh - 288px)">
          <DataTable :value="users" stripedRows scrollable scrollHeight="flex">
            
            <Column field="id"
                    :header="$t('general.username')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="firstname"
                    v-if="selectedColumns.includes('general.firstName')"
                    :header="$t('general.firstName')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="name"
                    v-if="selectedColumns.includes('general.name')"
                    :header="$t('general.name')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="role"
                    v-if="selectedColumns.includes('general.role')"
                    :header="$t('general.role')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="country"
                    v-if="selectedColumns.includes('general.country')"
                    :header="$t('general.country')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="groups"
                    v-if="selectedColumns.includes('general.group')"
                    :header="$t('general.group')"
                    class="flex-center"
                    style="overflow: hidden">
              <template #body="slotProps">
                <RoundedLabel
                  v-if="slotProps.data.groups"
                  v-for="group in slotProps.data.groups"
                  @click="
                    $store.commit('ADD_FILTER_BREADCRUMB', {
                      name: group.name,
                      type: 'GROUP',
                    });
                    $emitter.emit('search-users', null);
                  "
                  :text="group.name"
                  :lineThrough="!checkGroupExistence(group.name)"
                  class="bor-menu-color"
                  style="cursor: pointer; margin-right: 10px"
                />
              </template>
            </Column>
            <Column field="email"
                    v-if="selectedColumns.includes('general.eMail')"
                    :header="$t('general.eMail')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            
            <Column header="">
              <template #header="slotProps">
                <MultiSelect
                  v-model:model-value="selectedColumns"
                  style="width: 10px; height: 0"
                  :options="allColumns"
                >
                  <template #header="slotProps">
                    <h4>
                      Select Columns
                    </h4>
                  </template>
                  <template #option="s">
                    {{$t(s.option)}}
                  </template>
                </MultiSelect>
              </template>
              <template #body="slotProps">
                <div class="flex-center" style="width: 100%">
                  <RoundedLabel
                    v-if="hasPermission('manage_users/edit/users')"
                    @click="openManageUserDialog(slotProps.data)"
                    leftIcon="pi pi-cog"
                    v-tooltip="$t('tooltip.editUser')"
                    class="bor-menu-color"
                    style="cursor: pointer"
                  />
                  
                  <RoundedLabel
                    v-if="slotProps.data.id !== 'admin' && hasPermission('manage_users/delete/users')"
                    @click="
                    $store.commit('SHOW_CONFIRM_DIALOG', {
                      dialogTitle: 'dialog.areYouSure',
                      dialogContent: { t: 'dialog.deleteUser', e: [slotProps.data.id] },
                      confirmCallback: () => {
                        deleteUser(slotProps.data);
                        $store.commit('SHOW_SNACKBAR', {
                          snackbarContent: { t: 'snackbar.userDeleted' },
                          snackbarBtnText: $t('snackbar.undo'),
                          snackbarBtnCallback: () => {
                            undoDeleteUser();
                            $store.commit('SHOW_SNACKBAR', {
                              snackbarText: 'snackbar.userRestored',
                              snackbarContent: { t: 'snackbar.userRestored' },
                              snackbarType: 'info',
                              snackbarLife: 3000
                            });
                          },
                        });
                      },
                    })
                  "
                    v-tooltip="$t('tooltip.deleteUser')"
                    leftIcon="pi pi-trash"
                    class="bor-menu-color"
                    style="margin-left: 10px; cursor: pointer"
                  />
                </div>
              </template>
            </Column>
          </DataTable>
          
          
          <ManageUserDialog v-model:is-visible="manageUserDialog"
                            :user="currentUser"/>
        </div>
      </TabPanel>
      <TabPanel
        :header="$t('general.groups')"
        :disabled="showAddUserOrGroup || showEditUser || showEditGroup"
      >
        
        <div style="height: calc(100vh - 288px)">
          <DataTable :value="groups" stripedRows scrollable scrollHeight="flex">
            <Column field="id"
                    :header="$t('general.id')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="name"
                    v-if="selectedGroupColumns.includes('general.name')"
                    :header="$t('general.name')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="costCenter"
                    v-if="selectedGroupColumns.includes('general.costCenter')"
                    :header="$t('general.costCenter')"
                    class="flex-center"
                    style="overflow: hidden">
            </Column>
            <Column field="jobs"
                    v-if="selectedGroupColumns.includes('general.assetCount')"
                    :header="$t('general.assetCount')"
                    class="flex-center"
                    style="overflow: hidden">
              <template #body="slotProps">
                {{(groupInformation.get(slotProps.data.name) ? groupInformation.get(slotProps.data.name).assets : 0)}}
              </template>
            </Column>
            <Column field="assets"
                    v-if="selectedGroupColumns.includes('general.jobCount')"
                    :header="$t('general.jobCount')"
                    class="flex-center"
                    style="overflow: hidden">
              <template #body="slotProps">
                {{(groupInformation.get(slotProps.data.name) ? groupInformation.get(slotProps.data.name).jobs : 0)}}
              </template>
            </Column>
            
            <Column header="">
              <template #header="slotProps">
                <MultiSelect
                  v-model:model-value="selectedGroupColumns"
                  style="width: 10px; height: 0"
                  :options="allGroupColumns"
                >
                  <template #header="slotProps">
                    <h4>
                      Select Columns
                    </h4>
                  </template>
                  <template #option="s">
                    {{$t(s.option)}}
                  </template>
                </MultiSelect>
              </template>
              <template #body="slotProps">
                <div class="flex-center" style="width: 100%">
                  <RoundedLabel
                    v-if="hasPermission('manage_users/delete/groups')"
                    @click="openDeleteUserDialog(slotProps.data)"
                    v-tooltip="$t('tooltip.deleteGroup')"
                    leftIcon="pi pi-trash"
                    class="bor-menu-color"
                    style="cursor: pointer"
                  />
                </div>
              </template>
            </Column>
          </DataTable>
          
          
          <ManageUserDialog v-model:is-visible="manageUserDialog"
                            :user="currentUser"/>
        </div>
        
        <pDialog v-model:visible="deleteGroupDialog" :header="'Delete Group'">
          <div
            style="height: 350px; background-color: transparent; display: flex; flex-direction: column; align-items: center; justify-content: space-between">
            <div style="gap: 50px; flex-direction: column" class="flex-center">
              <div style="display: flex; gap: 12px">
                <i class="pi pi-info-circle" style="padding-top: 3px"/>
                <div style="display: flex; gap: 3px">
                  There are users and assets using this group. Choose a group to replace
                  '
                  <div style="font-weight: bold">
                    {{currentDeleteUserGroup.name}}
                  </div>
                  '
                </div>
              
              </div>
              
              <AutoCompleteGroup style="width: 228px" v-model:model-value="currentReplaceDeleteUserGroup"/>
            </div>
            
            <div style="width: 100%; display: flex; justify-content: space-between">
              
              <Button
                @click="deleteGroupDialog = false; currentDeleteUserGroup = undefined"
                :label="$t('general.cancel')"
                class="border-radius-8 col-white bor-transparent bg-dark-blue"
                style="width: 110px; height: 40px; padding: 7px 6px 6px 8px"
              />
              
              
              <Button
                @click="deleteGroup(currentDeleteUserGroup, currentReplaceDeleteUserGroup)"
                :label="$t('general.ok')"
                class="border-radius-8 col-white bor-transparent bg-dark-blue"
                style="width: 110px; height: 40px; padding: 7px 6px 6px 8px"
              />
            </div>
          </div>
        </pDialog>
      </TabPanel>
      
      
      <TabPanel
        v-if="isApproveUserAllowed"
        :header="$t('manageUsers.approveUser')"
        :disabled="showAddUserOrGroup || showEditUser || showEditGroup">
        <div style="height: calc(100vh - 288px)">
          <div v-if="approvalUsers.length === 0" style="display: flex; justify-content: center; padding-top: 50px">
            No users to approve
          </div>
          <DataTable v-if="approvalUsers.length > 0"
                     class="p-datatable-replace-media"
                     :value="approvalUsers"
                     scrollable
                     scrollHeight="flex"
                     removableSort
                     sortMode="multiple"
                     @rowSelect="onClickApprovalUserRow"
                     selectionMode="single"
                     :rows="8">
            <Column field="id" header="Id" sortable class="flex-center"></Column>
            <Column field="firstname" header="Firstname" sortable class="flex-center"></Column>
            <Column field="name" header="Lastname" sortable class="flex-center"></Column>
            <Column field="costCenter" header="CostCenter" sortable class="flex-center"></Column>
            <Column header="" class="flex-center">
              <template #body="slotProps">
                <span
                  class="transitions flex-center"
                >
                  
                  <RoundedLabel
                    @click="
                      $store.commit('SHOW_CONFIRM_DIALOG', {
                        dialogTitle: 'dialog.areYouSure',
                        dialogContent: {
                          t: 'dialog.deleteJob',
                          e: [slotProps.data.name],
                        },
                        confirmCallback: () => {
                          deleteApproveUser(slotProps.data);
                          $store.commit('SHOW_SNACKBAR', {
                            snackbarContent: { t: 'snackbar.jobDeleted' },
                            snackbarBtnText: $t('snackbar.undo'),
                          });
                        },
                      })
                    "
                    leftIcon="pi pi-trash"
                    class="bor-menu-color"
                    style="margin-right: 10px; cursor: pointer"
                  />
      
                </span>
              </template>
            </Column>
          </DataTable>
          
          <ManageUserDialog :user="selectedApprovalUser" :is-visible="approvalUsersDialog"></ManageUserDialog>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script src="./ManageUsersView.ts"></script>
