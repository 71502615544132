<template>
  <transition name="fade">
    <div
      class="modal-overlay"
      v-if="$store.state.addFilterDialog.visible"
      @click="$store.state.addFilterDialog.visible = false"
    ></div>
  </transition>
  
  <pDialog :header="$t('general.addFilter')"
           style="width: 50%;"
           v-model:visible="$store.state.addFilterDialog.visible">
    <div style="display: flex; flex-direction: column; align-items: center; width: 100%">
      
      <div style="display: flex; flex-direction: column; align-items: center; width: 100%">
        <AssetStatusDropdown
          v-if="$store.state.addFilterDialog.showStatus"
          v-model:modelValue="currentStatus"
          style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
            "
        />
        <AutoCompleteLanguage
          v-if="$store.state.addFilterDialog.showLanguage"
          v-model="currentLanguage"
          class="bor-menu-color border-radius-8"
          style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
            "
        />
        <AutoCompleteGroup
          v-if="userIsAdmin && $store.state.addFilterDialog.showGroup"
          v-model="currentGroup"
          :dropdown-height="204"
          class="bg-transparent border-radius-8 bor-menu-color"
          style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
            "
        />
        
        <UserRoleDropdown
          v-if="$store.state.addFilterDialog.showRoleDropdown"
          v-model="currentRole"
          style="
              width: 90%;
              min-width: 256px;
              height: 50px;
              float: left;
              margin-top: 24px;
            "
        />
        
        <CustomInputText
          v-if="$store.state.addFilterDialog.showCostCenter"
          v-model:modelValue="currentCostCenter"
          :label="$t('general.costCenter')"
          style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              justify-content: center;
            "
          class="bg-transparent border-radius-12 col-dark-blue bor-dark-blue-60"
        />
        
        
        <AutoCompleteUser
          v-if="$store.state.addFilterDialog.showTranslator"
          v-model="currentTranslator"
          :label="$t('general.translator')"
          :userRoles="
              () => {
                return [UserRole.TRANSLATOR];
              }
            "
          :dropdown-height="204"
          class="bor-menu-color border-radius-8"
          style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              font-size: 16px;
            "
        />
        <CustomDropdown
          v-if="$store.state.addFilterDialog.showCustomDropdown"
          v-model="currentCustom"
          :options="customOptions"
          show-clear="true"
          optionLabel="name"
          :dropdown-height="130"
          :placeholder="$t('general.metadata')"
          :label="$t('general.metadata')"
          class="bor-menu-color border-radius-8"
          style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              font-size: 16px;
            "
        />
        <div v-if="currentCustom !== undefined"
             class="bor-menu-color border-radius-8"
             style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              font-size: 16px;
            ">
          <CustomerMetadataInput :metadata="currentCustom" style="border: none; width: 95%; overflow: hidden"/>
        </div>
      </div>
      
      <div
        style="
            width: 90%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
      >
        <Button
          @click="
              $store.commit('HIDE_DIALOG');
              currentLanguage = undefined;
              currentGroup = undefined;
              currentTranslator = undefined;
              currentStatus = undefined;
              currentRole = undefined;
              currentCostCenter = undefined;
              currentDefaultLanguage = undefined;
							currentCustom = undefined;
            "
          :label="$t('general.cancel')"
          class="border-radius-8 col-dark-blue bor-transparent bg-tt-white"
          style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
        />
        <Button
          @click="
              addFilters();
              $store.commit('HIDE_DIALOG');
              $emitter.emit('search-jobs', null);
              $emitter.emit('search-assets', null);
              $emitter.emit('search-users', null);
              $emitter.emit('search-asset-items', null);
              $emitter.emit('search-font-mapping', null);
              currentLanguage = undefined;
              currentGroup = undefined;
              currentTranslator = undefined;
              currentStatus = undefined;
							currentCustom = undefined;
            "
          :label="$t('general.ok')"
          class="border-radius-8 col-white bor-transparent bg-dark-blue"
          style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
        />
      </div>
    </div>
  </pDialog>
  
  <!--
  <transition name="pop">
    <div
      class="modal"
      style="display: flex; width: 42%; min-height: 20%; height: fit-content"
      v-if="$store.state.addFilterDialog.visible"
    >
      <div
        style="
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          class="col-dark-blue"
          style="
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 700;
          "
        >
          {{ $t("general.addFilter") }}
        </div>

        <div
          style="
            width: 85%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <AssetStatusDropdown
            v-model="currentStatus"
            v-if="$store.state.addFilterDialog.showStatus"
            style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
            "
          />
          <AutoCompleteLanguage
            v-model="currentLanguage"
            v-if="$store.state.addFilterDialog.showLanguage"
            class="bor-menu-color border-radius-8"
            style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
            "
          />
          <AutoCompleteGroup
            v-model="currentGroup"
            v-if="userIsAdmin && $store.state.addFilterDialog.showGroup"
            class="bg-transparent border-radius-8 bor-menu-color"
            style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
            "
          />

          <UserRoleDropdown
            v-model="currentRole"
            v-if="$store.state.addFilterDialog.showRoleDropdown"
            style="
              width: 90%;
              min-width: 256px;
              height: 50px;
              float: left;
              margin-top: 24px;
            "
          />

          <CustomInputText
            v-model:modelValue="currentCostCenter"
            v-if="$store.state.addFilterDialog.showCostCenter"
            :label="$t('general.costCenter')"
            style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              justify-content: center;
            "
          />

          <AutoCompleteLanguage
            v-model="currentDefaultLanguage"
            :placeholder="$t('general.defaultLanguage')"
            v-if="$store.state.addFilterDialog.showDefaultLanguage"
            class="bor-menu-color border-radius-8"
            style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
            "
          />

          <AutoCompleteUser
            v-model="currentTranslator"
            v-if="$store.state.addFilterDialog.showTranslator"
            :label="$t('general.translator')"
            :userRoles="
              () => {
                return [UserRole.TRANSLATOR];
              }
            "
            class="bor-menu-color border-radius-8"
            style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              font-size: 16px;
            "
          />
          <CustomDropdown
            v-model="currentCustom"
            v-if="$store.state.addFilterDialog.showCustomDropdown"
            :options="customOptions"
            optionLabel="name"
            :placeholder="$t('general.metadata')"
            :label="$t('general.metadata')"
            class="bor-menu-color border-radius-8"
            style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              font-size: 16px;
            "
          />
          <div v-if="currentCustom !== undefined"
               class="bor-menu-color border-radius-8"
               style="
              width: 90%;
              min-width: 256px;
              min-height: 50px;
              float: left;
              margin-top: 24px;
              font-size: 16px;
            ">
            <CustomerMetadataInput :metadata="currentCustom" style="border: none; width: 95%; overflow: hidden"/>
          </div>
        </div>

        <div
          style="
            width: 90%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <Button
            @click="
              $store.commit('HIDE_DIALOG');
              currentLanguage = undefined;
              currentGroup = undefined;
              currentTranslator = undefined;
              currentStatus = undefined;
              currentRole = undefined;
              currentCostCenter = undefined;
              currentDefaultLanguage = undefined;
              currentCustom = undefined;
            "
            :label="$t('general.cancel')"
            class="border-radius-8 col-dark-blue bor-transparent bg-tt-white"
            style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
          />
          <Button
            @click="
              addFilters();
              $store.commit('HIDE_DIALOG');
              $emitter.emit('search-jobs', null);
              $emitter.emit('search-assets', null);
              $emitter.emit('search-users', null);
              $emitter.emit('search-asset-items', null);
              $emitter.emit('search-font-mapping', null);
              currentLanguage = undefined;
              currentGroup = undefined;
              currentTranslator = undefined;
              currentStatus = undefined;
              currentCustom = undefined;
            "
            :label="$t('general.ok')"
            class="border-radius-8 col-white bor-transparent bg-dark-blue"
            style="min-width: 120px; height: 40px; padding: 7px 6px 6px 8px"
          />
        </div>
      </div>
    </div>
  </transition>
  -->
</template>

<script src="./AddFilterDialog.ts"></script>
